body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fs-specialita{
    font-size: 12px;
}
 .fs-quote{
    font-size: 11px;
    padding: 0.1rem 0.1rem!important;
 }

 .fs-info-evento{
  font-size: 11px;
  font-weight: normal;
 }

 .fs-descrizione-evento{
  font-size: 14px;
 }

 .no-margin
{
  margin: 0px !important;
}

.fs-marcatore{
    font-size: 14px;
}

.font-weight-normal {
  font-weight: normal !important;
}

.box-impostazioni {
  border: 1px solid rgb(0, 0, 0);
  padding: 5px;
}

.title-box-impostazioni {
  background-color: black;
  color: white;
  padding: 5px;
  margin: -5px -5px 5px -5px;
}