body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fs-specialita{
    font-size: 12px;
}
 .fs-quote{
    font-size: 11px;
    padding: 0.1rem 0.1rem!important;
 }

 .fs-concessionario{
  font-size: 11px;
  padding: 0.1rem 0.5rem!important;
}

 .fs-info-evento{
  font-size: 11px;
  font-weight: normal;
 }

 .fs-descrizione-evento{
  font-size: 14px;
 }

 .no-margin
{
  margin: 0px !important;
}

.fs-marcatore{
    font-size: 14px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  margin: auto;
  animation: spinner 1.5s linear infinite;
}
.col-6 {
  flex: 0 0 auto;
  width: 49%;
}

.col-4 {
  flex: 0 0 auto;
  width: 32.4%;
}

.col-12 {
  flex: 0 0 auto;
  width: 99%;
}

.border-row {
  border-style: inset;
    border-color: dimgrey;
}

.spinner-container{
  position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px;
    margin-left: -50px;
    width: 200px;
    height: 100px;
}

.divisore-icone{
  
  border-right: 2px solid lightgray;
  margin: 3px;

}

/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 10px; /* Place content 10px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 14px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav .title {
  padding: 0px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #f1f1f1;
  display: block;
  transition: 0.3s;
  text-align: center;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.transitionmaincontent{
  transition: margin-left .5s;
  padding: 0px;
}

.navbarsetmargin {
  margin-left: -15px;
  margin-right: 0px;
  padding: 10px;
}

.input-checkbox[type=checkbox]{accent-color:#ff0000;}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.settings-voices{
  border-top: 3px solid rgb(0, 0, 0);
  margin-top: 3px;
}

.container-empty-page {
  position: absolute;
  top: 50%;
  left: 45%;
  margin: -50px;
  margin-left: -50px;

}

.badge-notification-mine {
  --bs-badge-font-size: 0.6rem;
  --bs-badge-padding-x: 0.45em;
  --bs-badge-padding-y: 0.2em;
  --bs-badge-margin-top: 1.9rem;
  --bs-badge-margin-left: -0.5rem;
  position: absolute !important;
  font-size: var(--bs-badge-font-size);
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  margin-top: var(--bs-badge-margin-top); 
  margin-left: var(--bs-badge-margin-left);

}

.bf-alert-banca {
  /*background-color: #f694aa;*/
  color: #ff5277;
}

.bf-alert-punta {
  /* background-color: #a6d8ff; */
  color: #5db9ff;
}